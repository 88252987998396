import React from 'react'
import 'swiper/swiper-bundle.min.css'
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import styled from 'styled-components'
import FadeUpWhenVisible from './FadeUpWhenVisible'
import { HomeSliders } from '../data/homeSlider'
import HeroSlide from './HeroSlide'
import useHeroCarouselQuery from '../hooks/useHeroCarouselQuery'

SwiperCore.use([Pagination])

const Container = styled(FadeUpWhenVisible)`
  height: 100%;
`

const SwiperWrapper = styled(Swiper)`
  margin-top: 5px;

  width: 100%;
  aspect-ratio: 425/490;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 0px;
    width: 100%;
    aspect-ratio: 1068/319;
  }

  .swiper-button-prev {
    color: #373f50;
    top: 55%;
    display: none;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: flex;
    }
    &:after {
      font-size: 15px;
    }
  }

  .swiper-button-next {
    color: #373f50;
    top: 55%;
    display: none;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: flex;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      bottom: 10px;
    }
    &:after {
      font-size: 15px;
    }
  }

  .swiper-pagination-bullets {
    bottom: 0px;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      bottom: 10px;
    }
  }

  .swiper-pagination-bullet {
    background-color: ${({ theme }) => theme.colors.secondary};
    width: 5px;
    height: 5px;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 8px;
      height: 8px;
    }
  }
`

interface Props {
  items: HomeSliders
}

const HeroCarousel: React.FC<Props> = ({ items }) => {
  const data = useHeroCarouselQuery()
  return (
    <Container>
      <SwiperWrapper
        modules={[Navigation, Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        centeredSlides={true}
        loop={true}
        navigation
        autoplay={{ delay: 7500, disableOnInteraction: false }}
        pagination={{ clickable: true }}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <HeroSlide data={data} item={item} />
          </SwiperSlide>
        ))}
      </SwiperWrapper>
    </Container>
  )
}

export default HeroCarousel
