import React from 'react'
import { reparationsLinks } from '../data/menu.context'
import { ITopRepair } from '../hooks/useTopRepairsQuery'
import { repairModelPageBuilder } from '../templates/RepairModelPage.context'
import CardList from './CardList'

interface TopRecoversProps {
  items: ITopRepair[]
}

const TopRecovers: React.FC<TopRecoversProps> = ({ items }) => (
  <CardList>
    <CardList.CardListHeader
      titleDesktop="Top des réparations"
      titleDescription="Cliquez sur le modèle de téléphone pour accéder à la page réparation"
    />
    <CardList.CardListGrid>
      {items.map((item, index) => (
        <CardList.CardListCard
          key={index}
          index={index}
          alt="Top des réparation"
          brand={item.brand ?? ''}
          imageUrl={item.imageURL ?? ''}
          link={repairModelPageBuilder(item.brand ?? '', item.modeleSlug ?? '')}
          model={item.modele ?? ''}
          price={item.price ?? 0}
          label={item.label ?? ''}
        />
      ))}
      {/* //retrouvez tous nos appareils */}
      <CardList.CardListLastCard
        key={items.length + 1}
        index={items.length + 1}
        label={'Retrouvez tous nos modèles à réparer !'}
        buttonLabel={'Je répare'}
        link={reparationsLinks.all}
      ></CardList.CardListLastCard>
    </CardList.CardListGrid>
  </CardList>
)

export default TopRecovers
