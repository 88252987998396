import React from 'react'
import styled from 'styled-components'
import FadeUpWhenVisible from './FadeUpWhenVisible'
import AspectRatio from './AspectRatio'
import LinkExtended from './LinkExtended'
import { StaticImage } from 'gatsby-plugin-image'
import theme from '../theme/theme'
import Button from './Button'

const LastCardLabel = styled.label`
  color: white;
  text-align: center;
  font-weight: 700;
  margin-bottom: 33px;
`

const LastCardButton = styled(Button)`
  font-size: 12px;
  border: 3px solid white;
  padding: 2px 17px;
  border-radius: 9px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));
`
interface CardListCardLastProps {
  link: string
  label: string
  buttonLabel: string
  index: number
}

const CardListLastCard: React.FC<CardListCardLastProps> = ({
  link,
  label,
  buttonLabel,
  index,
}) => (
  <CardListCardRatio
    desktop={{ width: 160, height: 208 }}
    mobile={{ width: 160, height: 208 }}
  >
    <FadeUpWhenVisible delay={index * theme.transitions.fadeUp.delay}>
      <CardListCardLink color="#00BFB2" to={link} noprefetch>
        {/* label */}
        <LastCardLabel>{label}</LastCardLabel>
        {/* //button */}
        <LastCardButton>{buttonLabel}</LastCardButton>
      </CardListCardLink>
    </FadeUpWhenVisible>
  </CardListCardRatio>
)

const CardListWrapper = styled.div`
 
`
const CardListTitleDesktop = styled.h2`
  display: block;
  background-repeat: no-repeat;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  color: ${({ theme }) => theme.colors.secondary};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 2px;
    line-height: 24px;
    font-size: 20px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.secondary};
    max-width: min(100%, 100vw);
    text-align: center;
  }
`

interface Props {
  children: React.ReactNode
}

const CardListDescription = styled.p`
  display: none;
  padding: 0 10px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 31px;
  color: ${({ theme }) => theme.colors.body};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`

const CardListGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 160px);
  align-items: center;
  gap: 10px;
  margin: 0 auto;
  justify-content: space-around;
  padding-top: 5px;
`

interface CardListHeaderProps {
  titleDesktop?: React.ReactNode
  titleDescription?: string
}
const CardListHeader: React.FC<CardListHeaderProps> = ({
  titleDesktop,
  titleDescription,
}) => (
  <FadeUpWhenVisible>
    {titleDesktop && (
      <CardListTitleDesktop>{titleDesktop}</CardListTitleDesktop>
    )}
    {titleDescription && (
      <CardListDescription>{titleDescription}</CardListDescription>
    )}
  </FadeUpWhenVisible>
)

const CardListCardRatio = styled(AspectRatio)`
  width: 100%;
  max-width: 160px;
`

const CardListCardLink = styled(LinkExtended)<{ color?: string }>`
  background: ${({ color }) => color ?? `#ffffff`};
  box-shadow: -4px 4px 7px 0 #00000021;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  text-decoration: none;
  height: 100%;
  width: 100%;
  padding: 0 10px;
  &:hover {
    box-shadow: -1px 6px 18px 5px #0000001A;
  }
`

const CardListImgContainer = styled.span`
  width: 100%;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    display: inline-flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;

    & > * {
      width: 100%;
      height: 100%;
    }

    img {
      object-fit: contain !important;
    }
  }
`

const CardListInfosWrapper = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  text-align: center;
`

const formatPrice = (price: number) =>
  new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(
    price
  )

const CardListPriceWrapper = styled.div`
  font-size: 12px;
  color: #000;
`

const CardListPriceNumber = styled.span`
  font-size: 15px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.secondary};
`

interface CardListImgProps {
  imageUrl: string
  model: string
  alt: string
}

const CardListImg: React.FC<CardListImgProps> = ({ imageUrl, model, alt }) => {
  return (
    <CardListImgContainer>
      <span>
        {imageUrl ? (
          <img src={imageUrl ?? ''} alt={model ?? alt ?? 'image save.co'} />
        ) : (
          <StaticImage
            src="../images/SmartphoneDefault.png"
            alt={model ?? alt ?? 'image save.co'}
            quality={100}
            placeholder="blurred"
            width={170}
            height={170}
          />
        )}
      </span>
    </CardListImgContainer>
  )
}

interface CardListInfosProps {
  brand: string
  model: string
  type: string
}

const CardListInfos: React.FC<CardListInfosProps> = ({
  brand,
  type,
  model,
}) => (
  <CardListInfosWrapper>
    <div>
      {brand} {model}
    </div>
    <div style={{ marginTop: '2px' }}>{type}</div>
  </CardListInfosWrapper>
)

interface CardListPriceProps {
  price: number
}

const CardListPrice: React.FC<CardListPriceProps> = ({ price }) => (
  <CardListPriceWrapper>
    à partir de <CardListPriceNumber>{formatPrice(price)}</CardListPriceNumber>
  </CardListPriceWrapper>
)

interface CardListCardProps {
  brand: string
  model: string
  label?: string
  price: number
  link: string
  alt: string
  imageUrl: string
  index: number
}

const CardListCard: React.FC<CardListCardProps> = ({
  brand,
  model,
  label,
  price,
  link,
  alt,
  imageUrl,
  index,
}) => (
  <CardListCardRatio
    desktop={{ width: 160, height: 208 }}
    mobile={{ width: 160, height: 208 }}
  >
    <FadeUpWhenVisible delay={index * theme.transitions.fadeUp.delay}>
      <CardListCardLink to={link} target="_blank" noprefetch>
        <CardListImg model={model} imageUrl={imageUrl} alt={alt} />
        <CardListInfos
          brand={brand ?? ''}
          model={model ?? ''}
          type={label ?? ''}
        />
        <CardListPrice price={price ?? 0} />
      </CardListCardLink>
    </FadeUpWhenVisible>
  </CardListCardRatio>
)

interface Props {
  children: React.ReactNode
}
const CardList: React.FC<Props> = ({ children }) => (
  <CardListWrapper>{children}</CardListWrapper>
)

export default Object.assign(CardList, {
  CardListHeader,
  CardListGrid,
  CardListCard,
  CardListLastCard,
})
