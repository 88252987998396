import { useStaticQuery, graphql } from 'gatsby'
import { BlogCategoriesQuery } from '../_generated/codegen/graphqlTypes'
import notEmpty from '../utils/notEmpty'
import { Unpacked } from '../utils/Unpacked'

export type ICategory = NonNullable<
  Unpacked<NonNullable<BlogCategoriesQuery['strapi']['categories']>>
>

const useCategoriesQuery = (): ICategory[] => {
  const { strapi }: BlogCategoriesQuery = useStaticQuery(
    graphql`
      query BlogCategories {
        strapi {
          categories: blogCategories(where: { slug: "actualités-save" }) {
            id
            name
            slug
            posts {
              description
              title
              slug
              published_at
              cover {
                name
                url
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      quality: 100
                      placeholder: BLURRED
                      formats: [AVIF, WEBP, PNG]
                      width: 205
                      height: 160
                    )
                  }
                }
              }
              category {
                slug
              }
            }
          }
        }
      }
    `
  )
  const items = strapi.categories?.filter(notEmpty) ?? []
  return items.filter((x) => (x.posts?.length ?? 0) > 0)
}

export default useCategoriesQuery
