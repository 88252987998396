type TrustUsBrand = {
  label: string
  icon: string
}

export type TrustUsBrands = TrustUsBrand[]

const trustUsBrands: TrustUsBrands = [
  { icon: '/images/INFC/bouygues.png', label: 'Bouygues Telecom' },
  { icon: '/images/INFC/backmarket.png', label: 'Backmarket' },
  { icon: '/images/INFC/samsung.png', label: 'Samsung' },
  { icon: '/images/INFC/orange.png', label: 'Orange' },
  { icon: '/images/INFC/free.png', label: 'Free' },
]

export default trustUsBrands
