import React from 'react'
import styled from 'styled-components'
import { actualiteLink, servicesLink } from '../data/menu'
import AspectRatio from './AspectRatio'
import FadeUpWhenVisible from './FadeUpWhenVisible'
import LinkExtended from './LinkExtended'

const AspectRatioWrapper = styled(AspectRatio)`
  width: 100%;
  max-width: 323px;
  background: ${({ theme }) => theme.colors.secondary};
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
  }
`

const DiscoveryBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 26px;
`

const DiscoveryBlockDescription = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 35px;
  color: #ffffff;
  text-align: center;
  padding-bottom: 11px;
`

const DiscoveryBlockButton = styled(LinkExtended)`
  border: 4px solid #fff;
  border-radius: 9px;
  padding: 10px 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));
  font-style: normal;
  font-weight: 350;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  width: 100%;
  max-width: 224px;
  margin-top: 20px;
  transition: background-color 300ms ease, color 300ms ease;

  &:hover {
    background-color: white;
    color: ${({ theme }) => theme.colors.body};
  }
`

const DiscoveryBlock: React.FC = () => (
  <FadeUpWhenVisible>
    <AspectRatioWrapper
      mobile={{ width: 1, height: 1 }}
      desktop={{ width: 1, height: 1 }}
    >
      <DiscoveryBlockWrapper>
        <DiscoveryBlockDescription>
          Découvrez nos dernières actualités
        </DiscoveryBlockDescription>
        <DiscoveryBlockButton to={actualiteLink} noprefetch>
          En savoir plus
        </DiscoveryBlockButton>
      </DiscoveryBlockWrapper>
    </AspectRatioWrapper>
  </FadeUpWhenVisible>
)

const DiscoveryHeaderWrapper = styled.div`
  display: block;
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  padding: 32px 15px 10px 15px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`

const DiscoveryHeader: React.FC = () => (
  <DiscoveryHeaderWrapper>Nos actualités</DiscoveryHeaderWrapper>
)

const DiscoveryWrapper = styled.div`
  
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
    grid-template-columns: minmax(323px, 323px) minmax(0px, 100%);
    padding-top: 30px;
  }
`
interface Props {
  children?: React.ReactNode
}

const Discovery: React.FC<Props> = ({ children }) => (
  <DiscoveryWrapper>
    <DiscoveryBlock />
    <DiscoveryHeader />
    {children}
  </DiscoveryWrapper>
)

export default Discovery
