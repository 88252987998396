import React from 'react'
import HeroCarousel from '../components/HeroCarousel'
import Discovery from '../components/Discovery'
import DiscoveryCarousel from '../components/DiscoveryCarousel'
import TrustUs from '../components/TrustUs'
import trustUsBrands from '../data/trustUsBrands'
// import Testimonials from '../components/Testimonials'
import { PageProps } from 'gatsby'
// import useTestimonialsQuery from '../hooks/useTestimonialsQuery'
import { LayoutProps } from '../components/Layout.context'
import useTopRepairsQuery from '../hooks/useTopRepairsQuery'
import { magasinLink } from '../data/menu'
import { Helmet } from 'react-helmet'
import AboutBanner from '../components/AboutBanner'
import AboutContent from '../components/AboutContent'
import FranchiseGrid from '../components/FranchiseGrid'
import solutions from '../data/solutions'
import styled from 'styled-components'
import HomeSlider from '../data/homeSlider'
import useCategoriesQuery from '../hooks/useCategoriesQuery'
import { PostItem } from '../models/PostItem'
import TopRecovers from '../components/TopRecovers'

const HomeDiv = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: 0;
  }
`
const IndexPage: React.FC<PageProps> = () => {
  // const testimonials = useTestimonialsQuery()
  const topRepairs = useTopRepairsQuery()
  const items = useCategoriesQuery()
  const postList = (items[0].posts as unknown as PostItem[]).sort(
    (a, b) => Date.parse(b.published_at!) - Date.parse(a.published_at!)
  )

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Réparation de smartphones toutes marques sur place (Apple, Samsung, Xiaomi, Huawei, Honor...) et garantie 1 an !"
        />
      </Helmet>
      <HomeDiv>
        <HeroCarousel items={HomeSlider} />
        <AboutBanner
          title={'Près de 150 magasins en France'}
          button={
            <AboutBanner.Button to={magasinLink} noprefetch>
              Trouver mon magasin
            </AboutBanner.Button>
          }
        />
        <AboutContent colored noLimitWidth>
          <AboutContent.Title color="white">Nos solutions</AboutContent.Title>
          <FranchiseGrid items={solutions} />
        </AboutContent>
        <TopRecovers items={topRepairs} />
        <Discovery>
          <DiscoveryCarousel items={postList} />
          <TrustUs items={trustUsBrands} />
        </Discovery>
      </HomeDiv>

      {/* <Testimonials items={testimonials} /> */}
    </>
  )
}

const layoutProps: LayoutProps = {
  title: 'Leader européen de la réparation de smartphones et tablettes',
}

export default Object.assign(IndexPage, {
  layoutProps,
})
