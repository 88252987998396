import { useStaticQuery, graphql } from 'gatsby'
import notEmpty from '../utils/notEmpty'
import { Unpacked } from '../utils/Unpacked'
import { TopRepairsQuery } from '../_generated/codegen/graphqlTypes'

export type ITopRepair = NonNullable<
  Unpacked<NonNullable<TopRepairsQuery['strapi']['topRepairs']>>
>

const useTopRepairsQuery = (): ITopRepair[] => {
  const { strapi }: TopRepairsQuery = useStaticQuery(
    graphql`
      query TopRepairs {
        strapi {
          topRepairs(sort: "order", limit: 11) {
            price
            updated_at
            published_at
            modele
            modeleSlug
            label
            brand
            imageURL
          }
        }
      }
    `
  )

  return strapi.topRepairs?.filter(notEmpty) ?? []
}

export default useTopRepairsQuery
