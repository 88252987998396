import { useStaticQuery, graphql } from 'gatsby'

const useHeroCarouselQuery = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allFile(filter: { relativePath: { regex: "/^Slider.*/" } }) {
          edges {
            node {
              childrenImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  quality: 100
                  layout: CONSTRAINED
                  formats: [AVIF, WEBP]
                  avifOptions: { lossless: true, quality: 100 }
                  breakpoints: [1068, 1024, 640, 768]
                )
              }
              name
            }
          }
        }
      }
    `
  )
  return data.allFile.edges
}

export default useHeroCarouselQuery
