import { Link } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import usePostShare from '../hooks/usePostShare'
import { PostItem } from '../models/PostItem'
import { postPagePathBuilder } from '../templates/PostPage.context'
import notEmpty from '../utils/notEmpty'
import PostContent from './PostContent'
import { IBlogPostFeatured } from '../templates/BlogHomePage'

const Container = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  position: relative;
  min-height: 315px;
`

interface ImageLinkProps {
  short?: 'true'
}

const ImageLink = styled(Link)<ImageLinkProps>`
  height: 262px;
  width: 100%;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 412px;
  }

  ${({ short }) =>
    short === 'true' &&
    css`
      height: 128px;
      min-height: 128px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        height: 160px;
      }
    `}

  & > div {
    width: 100%;
    height: 100%;
  }
`

interface ImageProps {
  href: string
  short?: boolean
  image: IGatsbyImageData | string
  alt: string
}

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Image: React.FC<ImageProps> = ({ href, short, image, alt }) => (
  <ImageLink short={short ? 'true' : undefined} to={href}>
    {typeof image == 'string' ? (
      <Img src={image} alt={alt}></Img>
    ) : (
      <GatsbyImage image={image} alt={alt} />
    )}
  </ImageLink>
)

interface ContentProps {
  short?: boolean
}

const Content = styled.div<ContentProps>`
  padding: 15px;
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-areas:
    'title   print'
    'tags    1'
    'content content'
    '2       infos';

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 22px;
    grid-template-areas:
      'title   print'
      'content content'
      'tags    infos';
  }

  & > *[class^='PostContent__TitleContainer'] {
    grid-area: title;
  }

  & > *[class^='PostContent__TagsWrapper'] {
    grid-area: tags;
  }

  & > *[class^='PostContent__ShareActionsContainer'] {
    grid-area: print;
    justify-content: flex-end;
  }

  & > *[class^='BlogPostCard__SummaryWrapper'] {
    margin-top: 8px;
    grid-area: content;
  }

  & > *[class^='PostContent__InfosWrapper'] {
    grid-area: infos;
  }

  ${({ short }) =>
    short &&
    css`
      padding: 6px;
      grid-template-columns: 1fr;
      grid-template-areas:
        'infos'
        'title'
        'tags'
        'content';

      & > *[class^='BlogPostCard__SummaryWrapper'] {
        margin-top: 8px;
      }

      & > *[class^='PostContent__InfosWrapper'] {
        margin-top: -15px;
        transform: translateY(-10px);
        align-items: flex-end;
        justify-content: flex-end;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding: 5px 9px;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          'title title'
          'tags infos'
          'content content';

        & > *[class^='PostContent__InfosWrapper'] {
          margin-top: 0;
          transform: translateY(0);
        }
      }
    `}
`

interface SummaryWrapperProps {
  short?: boolean
}

const SummaryWrapper = styled.div<SummaryWrapperProps>`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.body};

  ${({ short }) =>
    short &&
    css`
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      & > div {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
      }
    `}
`

interface ReadMoreLinkProps {
  short?: 'true' | 'false'
}

const ReadMoreLink = styled(Link)<ReadMoreLinkProps>`
  display: inline-block;
  margin-top: 4px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.primary};
  white-space: nowrap;

  ${({ short }) =>
    short &&
    css`
      font-size: 12px;
      line-height: 14px;
      position: absolute;
      bottom: 5px;
      right: 5px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 12px;
        line-height: 19px;
      }
    `}
`

const ReadMoreLinkDiv = styled.div`
  height: 17px;
`

interface SummaryProps {
  summary: string
  short?: boolean
  href: string
  date?: string | null
}

const Summary: React.FC<SummaryProps> = ({ summary, short, date, href }) => (
  <SummaryWrapper short={short}>
    <div>{summary}</div>
    {!short && date && <PostContent.Date short={short} date={date} />}
    <ReadMoreLinkDiv>
      <ReadMoreLink short={short ? 'true' : undefined} to={href}>
        Lire la suite
      </ReadMoreLink>
    </ReadMoreLinkDiv>
  </SummaryWrapper>
)

interface BlogPostCardProps {
  short?: boolean
  item: PostItem | IBlogPostFeatured
}

const BlogPostCard: React.FC<BlogPostCardProps> = ({ item, short }) => {
  const link = useMemo(
    () => postPagePathBuilder(item.category?.slug ?? '', item.slug),
    [item.category, item.slug]
  )
  const share = usePostShare(item)

  const image = useMemo(() => {
    const imageData = item.cover?.imageFile?.childImageSharp?.gatsbyImageData
    if (imageData) {
      return getImage(imageData)
    } else if (item.cover?.url != undefined) {
      return item.cover.url
    }
    return undefined
  }, [])

  return (
    <Container>
      {image && (
        <Image
          href={link}
          short={short}
          image={image}
          alt={item.cover?.name ?? ''}
        />
      )}
      <Content short={short}>
        <PostContent.Title short={short} href={link}>
          {item.title}
        </PostContent.Title>
        {item.tags && (
          <PostContent.Tags short={short} items={item.tags.filter(notEmpty)} />
        )}
        {!short && <PostContent.ShareActions share={share} />}
        <Summary
          short={short}
          summary={item.description ?? ''}
          date={item.published_at}
          href={link}
        />
        <PostContent.Infos
          short={short}
          author={item.author}
          date={item.published_at}
        />
      </Content>
    </Container>
  )
}

export default BlogPostCard
