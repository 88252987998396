import { reparationLink, servicesLink, servicesLinks } from './menu'

export type IdentityItem = {
  title: string
  text: string
  url: string
  image: string
}

export type IdentityItems = IdentityItem[]

const services: IdentityItems = [
  {
    title: 'Réparation\ntoutes marques',
    image: '/images/reparation.png',
    url: reparationLink,
    text: '',
  },
  {
    title: 'Accessoires\n& Protections',
    image: '/images/accessoires-protection.png',
    url: servicesLinks.accessoires,
    text: '',
  },
  {
    title: 'Smartphones\nReconditionnés',
    image: '/images/reconditionnés.png',
    url: servicesLinks.reconditionnes,
    text: '',
  },
  {
    title: 'Nos offres\nde services',
    image: '/images/services.png',
    url: servicesLink,
    text: '',
  },
]

export default services
