import BackgroundImage from 'gatsby-background-image'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import React from 'react'
import styled from 'styled-components'
import LinkExtended from './LinkExtended'
import { IdentityItem } from '../data/homeSlider'
import { useMedia } from 'react-use'
import theme from '../theme/theme'

const Slider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;

  background-repeat: no-repeat;
  background-size: 100% 100%;
`

const Slide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 1068px;
  max-height: 490px;
`

const SliderContentDiv = styled.div<{ position: string }>`
  position: relative;
  top: 86px;
  width: 94vw;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    top: ${(props) => (props.position == 'left' ? '24px' : '12px')};
    left: ${(props) => (props.position == 'left' ? '-155px' : '125px')};
    width: 100%;
  }

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.md}) and (max-width: ${({ theme }) =>
      theme.breakpoints.md}) {
    top: ${(props) => (props.position == 'left' ? '7px' : '12px')};

    left: ${(props) => (props.position == 'left' ? '-87px' : '125px')};
  }
`

const SliderTitleSpan = styled.span`
  font-size: 22px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 11px;
  display: block;
  text-align: center;
  white-space: pre-wrap;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 31px;
  }

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.md}) and (max-width: ${({ theme }) =>
      theme.breakpoints.md}) {
    font-size: 25px;
  }
`

const SliderSubtitleSpan = styled.span`
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 18px;
  display: block;
  margin-bottom: 22px;

  line-height: 22px;
  white-space: pre-wrap;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 22px;
    margin-bottom: 22px;
    line-height: 22px;
  }

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.md}) and (max-width: ${({ theme }) =>
      theme.breakpoints.md}) {
    font-size: 18px;
    margin-bottom: 10px;
  }
  @media (max-width: 350px) {
    font-size: 15px;
  }
`

const Button = styled(LinkExtended)`
  // position: absolute;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.25);
  display: block;
  border-radius: 12px;
  text-decoration: none;
  font-size: 17px;
  line-height: 20px;
  padding: 5px 10px;
  transition: background-color 300ms ease;
  margin: auto;
  width: fit-content;
  padding: 5px 15px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 21px;
    padding: 10px 20px;
  }
  @media (min-width: ${({ theme }) =>
      theme.breakpoints.md}) and (max-width: ${({ theme }) =>
      theme.breakpoints.md}) {
    font-size: 18px;
    padding: 5px 15px;
  }
`

interface Props {
  data: any
  item: IdentityItem
}

const HeroSlide: React.FC<Props> = ({ data, item }) => {
  const isDesktop = useMedia(`(min-width: ${theme.breakpoints.md})`)

  const img = data.filter(
    (data: { node: { name: string } }) => data.node.name === item.image
  )

  const imgMobile = data.filter(
    (data: { node: { name: string } }) =>
      data.node.name === item.image + '-mobile'
  )

  const image = getImage(img[0].node.childrenImageSharp[0])
  const bgImage = convertToBgImage(image)

  const imageMobile = getImage(imgMobile[0].node.childrenImageSharp[0])
  const bgImageMobile = convertToBgImage(imageMobile)
  return (
    <Slide>
      <Slider>
        {isDesktop ? (
          <BackgroundImage
            Tag="section"
            // Spread bgImage into BackgroundImage:
            {...bgImage}
            preserveStackingContext
            alt={item.alt}
            style={{
              // Defaults are overwrite-able by setting one or each of the following:
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              position: 'unset',
            }}
          >
            <SliderContentDiv position={item.position}>
              <SliderTitleSpan>{item.title}</SliderTitleSpan>
              <SliderSubtitleSpan>
                {isDesktop ? item.subtitle : item.subtitleMobile}
              </SliderSubtitleSpan>
              <Button to={item.url}>
                {isDesktop
                  ? item.buttonLabel
                  : item.buttonLabelMobile ?? item.buttonLabel}
              </Button>
            </SliderContentDiv>
          </BackgroundImage>
        ) : (
          <BackgroundImage
            Tag="section"
            // Spread bgImage into BackgroundImage:
            {...bgImageMobile}
            preserveStackingContext
            alt={item.alt}
            style={{
              // Defaults are overwrite-able by setting one or each of the following:
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              position: 'unset',
            }}
          >
            <SliderContentDiv position={item.position}>
              <SliderTitleSpan>{item.title}</SliderTitleSpan>
              <SliderSubtitleSpan>{item.subtitleMobile}</SliderSubtitleSpan>
              <Button to={item.url}>
                {item.buttonLabelMobile ?? item.buttonLabel}
              </Button>
            </SliderContentDiv>
          </BackgroundImage>
        )}
      </Slider>
    </Slide>
  )
}

export default HeroSlide
