import React from 'react'
import { useMedia } from 'react-use'
import styled from 'styled-components'
import 'swiper/swiper-bundle.min.css'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import useSwiperRef from '../hooks/useSwiperRef'
import theme from '../theme/theme'
import AspectRatio from './AspectRatio'
import FadeUpWhenVisible from './FadeUpWhenVisible'
import LinkExtended from './LinkExtended'
import { PostItem } from '../models/PostItem'
import BlogPostCard from './BlogPostCard'

SwiperCore.use([Pagination])

const DiscoveryCarouselWrapper = styled.div`
  max-width: 100%;
  padding-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-bottom: 0;
  }
`

interface DiscoverSlideLinkProps {
  image: string
}

const DiscoverSlideLink = styled(LinkExtended)<DiscoverSlideLinkProps>`
  display: inline-flex;
  background-image: url(${({ image }) => image});
  background-size: cover;
  width: 100%;
  height: 100%;
  max-width: 205px;
  max-height: 205px;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  text-decoration: none;
  align-items: flex-end;
  justify-content: flex-start;
`

const DiscoverSlideLabel = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
  text-shadow: 0px 0px 15px #000000;
  margin-left: 20px;
  margin-bottom: 10px;
`

interface DiscoverSlideProps {
  image: string
  href: string
  label: string
  index: number
}

const DiscoverSlide: React.FC<DiscoverSlideProps> = ({
  image,
  label,
  href,
  index,
}) => (
  <FadeUpWhenVisible delay={(index + 1) * theme.transitions.fadeUp.delay}>
    <AspectRatio
      desktop={{ width: 1, height: 1 }}
      mobile={{ width: 1, height: 1 }}
    >
      <DiscoverSlideLink image={image} to={href} noprefetch>
        <DiscoverSlideLabel>{label}</DiscoverSlideLabel>
      </DiscoverSlideLink>
    </AspectRatio>
  </FadeUpWhenVisible>
)

const SwiperWrapper = styled(Swiper)`
  padding-bottom: 10px;

  & .swiper-slide {
    max-width: 184px;
    height: auto;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 12px;
    padding-right: 12px;

    & .swiper-slide {
      max-width: 205px;
      height: auto;
    }

    .swipper-pagination {
      position: absolute;
      left: 0;
    }
  }
  .swiper-pagination-progressbar-fill {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`

const NavigationPrevDiv = styled.div`
  right: 20px;
  left: auto;

  color: #0f4c81;

  &:after {
    font-size: 15px;
    font-weight: 700;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    color: white;
    right: auto;
    left: 0px;
  }
`
const NavigationNextDiv = styled.div`
  right: 5px;
  left: auto;
  color: #0f4c81;
  &:after {
    font-size: 15px;
    font-weight: 700;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    color: white;
    right: auto;
    left: 20px;
  }
`

const NavigationDiv = styled.div`
  position: relative;
  top: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    top: -25px;
    left: -60px;
  }
`

interface DiscoveryCarouselProps {
  items: PostItem[]
}

const DiscoveryCarousel: React.FC<DiscoveryCarouselProps> = ({ items }) => {
  const isDesktop = useMedia(`(min-width: ${theme.breakpoints.md})`)
  const [navigationPrevEl, navigationPrevRef] = useSwiperRef()
  const [navigationNextEl, navigationNextRef] = useSwiperRef()
  return (
    <DiscoveryCarouselWrapper>
      <SwiperWrapper
        modules={[Navigation, Autoplay]}
        spaceBetween={12}
        slidesPerView="auto"
        centeredSlides={isDesktop ? false : true}
        loop={isDesktop ? false : true}
        loopedSlides={items.length}
        pagination={{
          clickable: true,
          type: 'progressbar',
        }}
        // autoplay={{ delay: 2500, disableOnInteraction: false }}
        navigation={{
          prevEl: navigationPrevEl,
          nextEl: navigationNextEl,
        }}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <BlogPostCard key={index} item={item!} short />
          </SwiperSlide>
        ))}
      </SwiperWrapper>
      <NavigationDiv>
        <NavigationPrevDiv
          className="swiper-button-prev"
          ref={navigationPrevRef}
        ></NavigationPrevDiv>
        <NavigationNextDiv
          className="swiper-button-next"
          ref={navigationNextRef}
        ></NavigationNextDiv>
      </NavigationDiv>
    </DiscoveryCarouselWrapper>
  )
}

export default DiscoveryCarousel
